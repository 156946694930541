import PropTypes from 'prop-types';
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
    Activity as ActivityIcon, Archive, Calendar as EventIcon, DollarSign, UserCheck as PresentIcon,
    Users as UsersIcon
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import { User, USER_ROLES } from 'src/types/user';

/* eslint-disable no-use-before-define */
import {
    Box, Divider, Drawer, Hidden, Link, List, ListSubheader, makeStyles
} from '@material-ui/core';

import NavItem from './NavItem';

import type { FC, ReactNode } from 'react';
interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
  admin?: boolean;
}

interface Section {
  items: Item[];
  admin?: boolean;
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: 'Membership',
    items: [
      {
        title: 'Contributii',
        href: '/app/pub/budget',
        icon: DollarSign
      },
      {
        title: 'Ținute Rituale',
        href: '/app/pub/rituals',
        icon: EventIcon
      },

      {
        title: 'Prezenta',
        href: '/app/pub/present',
        icon: PresentIcon
      },
      {
        title: 'Evenimente',
        href: '/app/pub/articles',
        icon: EventIcon
      },
      {
        title: 'Arhiva',
        href: '/app/pub/archive',
        icon: Archive
      }
    ]
  },
  {
    subheader: 'Management',
    admin: true,
    items: [
      {
        title: 'Utilizatori',
        href: '/app/management/users',
        icon: UsersIcon
      },
      {
        title: 'Evenimente',
        href: '/app/management/articles',
        icon: EventIcon
      },
      {
        title: 'Prezenta',
        href: '/app/management/present',
        icon: PresentIcon
      },
      // {
      //   title: 'Emails',
      //   href: '/app/management/emails',
      //   icon: SendIcon
      // },
      {
        title: 'Activitate',
        href: '/app/management/activity-log',
        icon: ActivityIcon
      }
    ]
  }
];

function renderNavItems({
  items,
  pathname,
  depth = 0,
  userRole,
  user
}: {
  items: Item[];
  pathname: string;
  depth?: number;
  userRole: string;
  user: User;
}) {
  // Disable pages from here
  if (!user.eventAccess) items = items.filter((item) => item.href !== '/app/pub/articles');
  return (
    <List disablePadding>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth, userRole, user }), [])}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  userRole,
  user
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
  userRole: string;
  user: User;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });
    acc.push(
      <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          userRole,
          user
        })}
      </NavItem>
    );
  } else {
    if (item.admin && userRole !== USER_ROLES.Admin) {
      return acc;
    }
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
    backgroundColor: '#fffffff7'
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    backgroundColor: '#fffffff7'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user, role } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {/* <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden> */}
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Logo />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link component={RouterLink} to="/app/account" variant="h5" color="textPrimary" underline="none">
              Fr:. {user.memberData?.Grad} {user.firstName} {user.lastName} <br />
              {user.email}
            </Link>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => {
            if (section.admin && role !== USER_ROLES.Admin) return null;
            return (
              <List
                key={section.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                  userRole: role,
                  user: user
                })}
              </List>
            );
          })}
        </Box>
        <Divider />
        {/* <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
